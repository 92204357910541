@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  font-family: Economica;
}

/* .navbar-navbar-default {
    color: rgb(156, 40, 40);
} */


/* .navbar-brand.white {
  color: #fae257;
}

.navbar,
.navbar-inverse {
  border-radius: 0;
  border: none;
  margin-bottom: 0;
  min-height: 80px;
  background-color: #FFFB01;
}

.nav li {
  display: inline;
  color: white;
}

.navbar-inverse .navbar-nav>li>a {
  color: #fae257;
  font-size: 1.7em;
  font-weight: 300;
}
 */

.active {
  color: #fae257
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {

  opacity: 1;
}

.astext {
  background: none;
  border: none;
  margin: 0;
  padding: 0;
  cursor: pointer;
  color: black;
  font-size: 20px;
}

/* .dropdown-toggle {
  color: #1E1E1E
} */

.dropdown-a {
  font-size: 15px;
  color: #1E1E1E;
  top: 15px;
}

#socialmedia:hover {
  background-color: orange;
}


a:hover {
  cursor: pointer;
}

#buttonscroll:hover {
  background-color: orange;
}

#homebutton:hover {
  background-color: orange;
}

#membersonly:hover {
  background-color: orange;
}


.box {
  width: 40%;
  margin: 0 auto;
  background: #fae257;
  padding: 35px;
  border: 2px solid #fff;
  border-radius: 20px/50px;
  background-clip: padding-box;
  text-align: center;
}


.overlay {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.7);
  transition: opacity 500ms;
  visibility: hidden;
  opacity: 0;
  z-index: 10;
}

.overlay:target {
  visibility: visible;
  opacity: 1;
}

.popup {
  margin: 70px auto;
  padding: 20px;
  background: #fff;
  border-radius: 5px;
  width: 30%;
  position: relative;
  transition: all 5s ease-in-out;
}

.popup h2 {
  margin-top: 0;
  text-align: center;
  color: #333;
}

.popup .close {
  position: absolute;
  top: 10px;
  right: 30px;
  transition: all 200ms;
  font-size: 50px;
  font-weight: bold;
  text-decoration: none;
  color: #333;
}

.popup .close:hover {
  color: #CC3200;
}

.popup .content {
  max-height: 30%;
  overflow: auto;
  color: #1E1E1E;
}



@media screen and (max-width: 700px) {
  .box {
    width: 70%;
  }

  .popup {
    width: 70%;
  }
}

a:hover {
  cursor: pointer;
}


.box {
  width: 40%;
  margin: 0 auto;
  background: rgba(255, 255, 255, 0.2);
  padding: 35px;
  border: 2px solid #fff;
  border-radius: 20px/50px;
  background-clip: padding-box;
  text-align: center;
}


.overlay {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.7);
  transition: opacity 500ms;
  visibility: hidden;
  opacity: 0;
}

.overlay:target {
  visibility: visible;
  opacity: 1;
}

.mint {
  margin: 70px auto;
  padding: 20px;
  background: #fff;
  border-radius: 5px;
  width: 30%;
  position: relative;
  transition: all 5s ease-in-out;
}

.navbar-inverse .navbar-nav li a:hover {
  /* background-color: #444444; */
  /* transition: 0.7s all linear; */
  height: 100%;
  color: #f71505
}

.mint h2 {
  margin-top: 0;
  text-align: center;
  color: #333;

}

.mint .close {
  position: absolute;
  top: 10px;
  right: 30px;
  transition: all 200ms;
  font-size: 50px;
  font-weight: bold;
  text-decoration: none;
  color: #333;
}

.mint .close:hover {
  color: #CC3200;
}

.mint .content {
  max-height: 30%;
  overflow: auto;
  color: #1E1E1E;
  z-index: 10;
}


.popupinstall {
  margin: 70px auto;
  padding: 20px;
  background: #fff;
  border-radius: 5px;
  width: 30%;
  position: relative;
  transition: all 5s ease-in-out;




}



.popupinstall h2 {
  margin-top: 0;
  text-align: center;
  color: #333;

}

.popupinstall .close {
  position: absolute;
  top: 10px;
  right: 30px;
  transition: all 200ms;
  font-size: 50px;
  font-weight: bold;
  text-decoration: none;
  color: #333;
}

.popupinstall .close:hover {
  color: #CC3200;
}

.popupinstall .content {
  max-height: 30%;
  overflow: auto;
  color: #1E1E1E;
}

@media screen and (max-width: 700px) {
  .box {
    width: 70%;
  }

  .popup {
    width: 70%;
  }
}

.yellowbackground {
  background-color: #FFE401;
  height: 110px;
  width: 100%;
  /* position: fixed; */
  /* top: 0%; */
  z-index: 10;
}




/* .navbar-custom {
  background-color: yellow;
} */

a:hover {
  color: white;
}

h1 {
  color: white;
  font-size: 6em;
}

.container.leftmiddle {
  position: absolute;
  left: 15%;
  top: 40%;

}

html {
  background: url(../public/firecity.gif) no-repeat center center fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}

.bckgrnd {
  top: 123px;
  /* left: 1px; */
  width: 1511px;
  height: 900px;
  position: absolute;
  box-sizing: border-box;
  border-color: transparent;
  margin-right: 0;
  margin-bottom: 0;
  margin: 0;

}

.black-button {
  background-color: #1E1E1E;
  /* Green */
  border: none;
  color: white;
  padding: 15px 32px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 4px 2px;
  cursor: pointer;
  border-radius: 20px;
}





/* .container.transp {
  background-color: red;
} */

body {
  background-color: transparent;
  color: white;
}

html {
  scroll-behavior: smooth;

}

/* From uiverse.io by @SharpTH */
.btn-cssbuttons {
  position: relative;
  font-family: Economica;
  font-weight: 500;
  line-height: 1;
  text-align: center;
  color: black;
  background: none;
  border: none;
  outline: none;
  overflow: hidden;
  cursor: pointer;
  transition: 0.3s cubic-bezier(0.215, 0.61, 0.355, 1);
  font-size: 20px;
}

.btn-cssbuttons::before {
  position: absolute;
  content: "";
  top: 0;
  left: 0;
  z-index: -1;
  width: 100%;
  height: 100%;
  background: var(--btn-color);
  border-radius: 24px;
  transition: 0.3s cubic-bezier(0.215, 0.61, 0.355, 1);
}

.btn-cssbuttons span,
.btn-cssbuttons span span {
  display: inline-flex;
  vertical-align: middle;
  transition: 0.3s cubic-bezier(0.215, 0.61, 0.355, 1);
}

.btn-cssbuttons span {
  transition-delay: 0.05s;
}

.btn-cssbuttons span:first-child {
  padding-right: 0px;
}

.btn-cssbuttons span span {
  margin-left: 8px;
  transition-delay: 0.1s;
}

.btn-cssbuttons ul {
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  display: flex;
  margin: 0;
  padding: 0;
  list-style-type: none;
  transform: translateY(-50%);
}

.btn-cssbuttons ul li {
  flex: 1;
}

.btn-cssbuttons ul li a {
  display: inline-flex;
  vertical-align: middle;
  transform: translateY(55px);
  transition: 0.3s cubic-bezier(0.215, 0.61, 0.355, 1);
}

.btn-cssbuttons ul li a:hover {
  opacity: 0.5;
}

.btn-cssbuttons:hover::before {
  transform: scale(1.2);
}

.btn-cssbuttons:hover span,
.btn-cssbuttons:hover span span {
  transform: translateY(-55px);
}

.btn-cssbuttons:hover ul li a {
  transform: translateY(0);
}

.btn-cssbuttons:hover ul li:nth-child(1) a {
  transition-delay: 0.15s;
}

.btn-cssbuttons:hover ul li:nth-child(2) a {
  transition-delay: 0.2s;
}

.btn-cssbuttons:hover ul li:nth-child(3) a {
  transition-delay: 0.25s;
}


/* From uiverse.io by @nikk7007 */
.coolbutton {
  /* padding: 0.6em 1em; */
  /* border: 2px solid #17C3B2; */

  position: relative;
  overflow: hidden;
  background-color: transparent;
  text-align: center;
  text-transform: uppercase;
  font-size: 16px;
  transition: .3s;
  z-index: 1;
  font-family: inherit;
  color: black;
  transition: 0.5s;
}

.coolbutton::before {
  content: '';
  width: 0;
  height: 300%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) rotate(45deg);
  background: orange;
  transition: .5s ease;
  display: block;
  z-index: -1;
  color: orange;
}

.coolbutton:hover::before {
  width: 100%;
  color: orange;
}

.coolbutton:hover {
  color: orange;
}

.btn-1,
.btn-2 {
  width: 300px;
  height: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

/* Created by http://grohit.com/  */
footer a,
.btn-1 a,
.btn-2 a {
  text-decoration: none;
  border: 2px solid #010100;
  padding: 15px;
  color: #000;
  text-transform: uppercase;
  letter-spacing: 2px;
  position: relative;
  display: inline-block;
}

span {
  position: relative;
  /* z-index coz when we put bg to before and after this span text will not be visible */
  z-index: 3;
}

/* Button 1 styles */

.btn-1 a::before {
  content: "";
  position: absolute;
  top: 5px;
  left: -2px;
  width: calc(100% + 6px);
  /*100% plus double the times left values*/
  height: calc(100% - 10px);
  background-color: #ffffff;
  transition: all 0.5s ease-in-out;
  transform: scaleY(1);
}

.btn-1 a:hover::before,
.btn-2 a:hover::before {
  transform: scaleY(0);
}

.btn-1 a::after {
  content: "";
  position: absolute;
  left: 5px;
  top: -5px;
  width: calc(100% - 10px);
  /*100% plus double the times left values*/
  height: calc(100% + 10px);
  background-color: #ffffff;
  transition: all 0.5s ease-in-out;
  transform: scaleX(1);
}

/* Created by http://grohit.com/  */

.btn-1 a:hover::after,
.btn-2 a:hover::after {
  transform: scaleX(0);
}

.black-button:hover {
  background-color:orange;
  transition: 0.7s;
}
